// OrderDetailsManufacturer.js
import React, { useMemo, useState } from 'react';
import Container from "@material-ui/core/Container";
import { MaterialReactTable } from 'material-react-table';
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import { requireAuthManufacturer,useAuth } from "../../util/auth";
import {getOrderDetails} from "../../util/db";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    padding: `0 ${theme.spacing(3)}px`,
  },
  printButton: {
    backgroundColor: '#4caf50',
    color: 'white',
    '&:hover': {
      backgroundColor: '#45a049',
    },
  }
}));

function OrderDetailsManufacturer() {
  const auth = useAuth();
  const classes = useStyles();
  
  // Mock data for manufacturer orders
  const [data, setData] = useState([]);
  const getOrderData = async () => {
    const odata = await getOrderDetails();
    console.log(odata);
    setData(odata);
  }
  
  React.useEffect(() => {
    getOrderData();

  }, [])


  const handlePrintLabel = () => {
    // Open the existing PDF file in a new tab for print preview
    window.open('/label_order.pdf', '_blank');
  };
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.createdAt.toDate().toLocaleDateString('en-US') ,
        header: 'Date',
        size: 50,
      },
      {
        accessorFn: (row) => row.id,
        header: 'Order Id',
      },

      {
        accessorFn: (row) => row.buyerEmail,
        header: 'Customer Email',
      },
      {
        accessorKey: 'manufacturerAmount',
        header: 'Your Amount',
      },
      {
        accessorKey: 'manufacturerPaymentStatus',
        header: 'Manufacturer Payment Status',
      },
      // {
      //   accessorFn: (row) => row.priceSummary.subtotal.value,
      //   header: 'Total',
      // },
      {
        accessorFn: (row) => row.priceSummary.total.currency,
        header: 'Currency',
      },
      {
        id: 'actions',
        header: 'Actions',
        Cell: ({ row }) => (
          <Button
            variant="contained"
            className={classes.printButton}
            onClick={() => handlePrintLabel(row.original)}
          >
            Print Label
          </Button>
        ),
      },

    ],
    []
  );

  return (
    <Container className={classes.container}>
      <br />
      <h2>Order Details - Manufacturer</h2>
      <br />
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnFilters
        enableSorting
        enablePagination
        initialState={{
          pagination: { pageSize: 5, pageIndex: 0 },
        }}
      />
    </Container>
  );
}

export default requireAuthManufacturer(OrderDetailsManufacturer);