import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "./firebase";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const functions = getFunctions(firebaseApp);

export async function onboardUserToWix(userData) {
    try {
        const onboardUserToWixFn = httpsCallable(functions, "onboardUserToWix");
        const response = await onboardUserToWixFn(userData);
        
        if (response.data.success) {
            toast.success("User onboarded successfully!");
            return response.data;
        } else {
            toast.error("Failed to onboard user");
            return null;
        }
    } catch (error) {
        console.error("Error onboarding user:", error);
        toast.error(error.message || "Failed to onboard user");
        return null;
    }
}


export const createOnboardingLink = async (formData) => {
    try {
      const createOnboarding = httpsCallable(functions, "createOnboardingLink");
      const result = await createOnboarding(formData);
      return result.data.url; // Return the URL from the Cloud Function response
    } catch (error) {
      console.error("Error calling createOnboardingLink:", error.message);
      throw error; // Re-throw the error for the caller to handle
    }
  };



  export const processSellerPayout = async (orderDetails) => {
    try {
      const processPayout = httpsCallable(functions, "processSellerPayout");
      const result = await processPayout({
        orderId: orderDetails.docid,
        orderUid: orderDetails.id,
        latestCharge: orderDetails.latestCharge,
        destinationAccount: orderDetails.destinationAccount,
        currency: orderDetails.currency,
        amount: orderDetails.commissionAmount,
        email: orderDetails.sellerEmail
      });
      
      return result.data;
    } catch (error) {
      console.error("Error processing seller payout:", error.message);
      throw error;
    }
  };