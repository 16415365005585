import React from "react";
import Meta from "../../components/Meta";
import DashboardSectionMaker from "../../components/DashboardSectionMaker";
import { requireAuthManufacturer } from "../../util/auth";

function DashboardPageMaker(props) {
  return (
    <>
      <Meta title="Dashboard" />
      <DashboardSectionMaker
        bgColor="#EFEFEF"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Dashboard"
        subtitle=""
      />
    </>
  );
}

export default requireAuthManufacturer(DashboardPageMaker);
