import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import DashboardPage from "./seller/dashboard";
import DashboardPageAdmin from "./admin/dashboard";
import OnboardSeller from "./admin/onboard_seller";
import OnboardManufacturer from "./admin/onboard_manufacturer";
import OnboardingSuccess from "./admin/OnboardingSuccess";
import TransactionsAdmin from "./admin/transactions";
import SellerPayout from "./admin/seller_payout";
import ManageUsers from "./admin/manage_users";
import DashboardPageMaker from "./manufacturer/dashboard";
import AuthPage from "./auth";
import OnboardClient from "./seller/onboard_client";
import AfterAuth from "./afterauth";
import MyClients from "./seller/my_clients";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import ProjectedPaymentsSeller from './seller/projected_payment';
import OrderDetailsAdmin from "./admin/order_details";
import OrderDetailsManufacturer from "./manufacturer/order_details";
import MyPaymentsManufacturer from './manufacturer/my_payments';
import FirebaseActionPage from "./firebase-action";
import MyPayments from "./seller/my_payments";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import './index.css';
import { AuthProvider } from "./../util/auth";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";

function App(props) {
  return (
    <QueryClientProvider>
      <ThemeProvider>
        <AuthProvider>
          <Router>
            <>
              <Navbar
                color="default"
                logo="/logo.png"
                logoInverted="/logo.png"
              />

              <Switch>
                <Route exact path="/" component={IndexPage} />

                <Route exact path="/onboard_client" component={OnboardClient} />
                <Route exact path="/afterauth" component={AfterAuth} />

                <Route exact path="/my_clients" component={MyClients} />

                <Route exact path="/seller/dashboard" component={DashboardPage} />
                <Route exact path="/upcoming-payments" component={ProjectedPaymentsSeller} />
                <Route exact path="/admin/dashboard" component={DashboardPageAdmin} />
                <Route exact path="/admin/orders" component={OrderDetailsAdmin} />
                <Route exact path="/admin/transactions" component={TransactionsAdmin} />
                <Route exact path="/admin/payout" component={SellerPayout} />
                <Route exact path="/admin/users" component={ManageUsers} />
                <Route exact path="/admin/onboard-seller" component={OnboardSeller} />
                <Route exact path="/admin/onboard-maker" component={OnboardManufacturer} />
                <Route exact path="/supply/dashboard" component={DashboardPageMaker} />
                <Route exact path="/supply/orders" component={OrderDetailsManufacturer} />
                <Route exact path="/payments" component={MyPayments} />
                <Route exact path="/supply/payments" component={MyPaymentsManufacturer} />
                <Route exact path="/onboarding-success/:accountId" component={OnboardingSuccess} />

                {/* <Route exact path="/auth/:type" component={AuthPage} /> */}
                <Route exact path="/:role/auth/:type" component={AuthPage} />


                <Route exact path="/legal/:section" component={LegalPage} />

                <Route
                  exact
                  path="/firebase-action"
                  component={FirebaseActionPage}
                />

                <Route component={NotFoundPage} />
              </Switch>

              {/* <Footer
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="A short description of what you do here"
                copyright={`© ${new Date().getFullYear()} Company`}
                logo="https://uploads.divjoy.com/logo.svg"
                logoInverted="https://uploads.divjoy.com/logo-white.svg"
                sticky={true}
              /> */}
            </>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
