import React from "react";
import Meta from "../../components/Meta";
import DashboardSectionAdmin from "../../components/DashboardSectionAdmin";
import { requireAuthAdmin } from "../../util/auth";

function DashboardPageAdmin(props) {
  return (
    <>
      <Meta title="Dashboard" />
      <DashboardSectionAdmin
        bgColor="#EFEFEF"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Dashboard"
        subtitle=""
      />
    </>
  );
}

export default requireAuthAdmin(DashboardPageAdmin);
