import React, {useState} from 'react'
import { useForm, Controller } from 'react-hook-form';
import Typography from "@material-ui/core/Typography";
import Select from 'react-select';
import countryList from 'react-select-country-list';
import '../forms.css';
import {onboardUserToWix} from '../../util/functions';
import {CircularProgress} from "@material-ui/core";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createSeller } from '../../util/db';
import { useAuth } from "../../util/auth";
import { makeStyles } from "@material-ui/core/styles";
import { requireAuthAdmin } from "../../util/auth";
import { loadStripe } from "@stripe/stripe-js";
import { createOnboardingLink } from "../../util/functions";
import {
  ConnectComponentsProvider,
  ConnectAccountOnboarding,
} from "@stripe/react-connect-js";


const stripeConnectPromise = loadStripe("pk_live_51Q4yRoAmnF0ICVj258euTuypoqTg2tPbjppBx83E62cJYKBVvsq5slPz1nIePtCRf0SOcH5kFahxBOtOP8DaHSIq00ao6ZKR1I"); // Replace with your Stripe publishable key


const useStyles = makeStyles(() => ({
  formGroup: {
    marginBottom: '20px',
  },
  phoneInput: {
    width: '100%',
    height: '61px',
    padding: '10px 32px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '30px',
    backgroundColor: '#fff',
    transition: 'all 0.3s ease',
    boxSizing: 'border-box',
    '&:focus': {
      outline: 'none',
      borderColor: '#007bff',
      boxShadow: '0 0 0 2px rgba(0, 123, 255, 0.25)',
    },
  },
}));


function OnboardSeller() {

  const auth = useAuth();
  const classes = useStyles();
  const [selectPhone, setSelectPhone] = useState('');
  const [instance, setInstance] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const fetchOnboarding = async (formData) => {
    try {
      setLoadingSubmit(true);
  
      // Step 1: Fetch onboarding link
      const url = await createOnboardingLink(formData);
  
      if (url) {
        // Step 2: Send password reset email
        await auth
          .sendPasswordResetEmail(formData.email, {
            url: "https://app.cropdefense.net/auth/signin", // Redirect URL after reset
            handleCodeInApp: true,
          });
       
        // Step 3: Show success message
        toast.success(
          "Account created for the seller and reset password email sent successfully. Now you will be redirected to the Stripe onboarding process."
        );
        setLoadingSubmit(false);
  
        // Step 4: Redirect to Stripe onboarding
        setTimeout(() => {
          window.location.href = url;
        }, 1000);
      }
    } catch (error) {
      console.error("Failed to fetch onboarding link or send email:", error.message);
      toast.error(`An error occurred. Please try again.${error.message}`);
    } finally {
      // Reset loading state
      setLoadingSubmit(false);
    }
  };

  // const fetchInstance = async () => {
    
  //   const stripeConnectPromise = await loadStripe("pk_live_51Q4yRoAmnF0ICVj258euTuypoqTg2tPbjppBx83E62cJYKBVvsq5slPz1nIePtCRf0SOcH5kFahxBOtOP8DaHSIq00ao6ZKR1I");
  //   console.log(stripeConnectPromise)
  //   setInstance(stripeConnectPromise);

  // };

  // React.useEffect(() => {

  //   fetchInstance();

  // }, []);
  const { register, control, formState: { errors, isSubmitting } } = useForm({
      mode: 'onSubmit',
      defaultValues: {
        firstName: '',
        lastName: '',
          email: '',
          mobileNumber: '',
          nationality: null,
          companyName: '',
          countryOfOperations: null,
          designation: '',
          paymentPercentage: '',
      }
  });

  const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      nationality: null,
      companyName: '',
      countryOfOperations: null,
      designation: '',
      paymentPercentage: '',

    });


    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    };
  

    
    const handleSubmitData = async (e) => {
      e.preventDefault();
      setLoadingSubmit(true);
      if (formData.firstName === '' || formData.lastName === ''  || formData.email === '' || formData.mobileNumber === '' || formData.nationality === null || formData.representingCompany === null || formData.countryOfOperations === null || formData.designation === '' || formData.totalArea === ''   ){
          toast.error(`Fill up the form completely to submit`, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoadingSubmit(false);
            return

      }
      // console.log(formData)
    //   await onboardUserToWix(formData);
      // setLoadingSubmit(false);
      // await createSeller(formData);
      // toast.success(`seller Onboarded`, {
      //   position: "bottom-right",
      //   autoClose: 5000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      const fullName = [formData.firstName, formData.lastName].filter(Boolean).join(' ');
      await fetchOnboarding({...formData, profileName: fullName });
  }

  const countryOptions = countryList().getData();



  const customStyles = {
      control: (provided) => ({
          ...provided,
          borderRadius: '30px',
          border: '1px solid #fff',
          boxShadow: 'none',
          '&:hover': {
              border: '1px solid #fff',
          },
      }),
      menu: (provided) => ({
          ...provided,
          borderRadius: '15px',
      }),
      option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#287B4C' : state.isFocused ? '#f0f0f0' : null,
          color: state.isSelected ? 'white' : 'black',
      }),
  };

  return (
      <>
      <ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"

/>

{(loadingSubmit) && (
              <div className="loading-overlay" style={{display:"flex", flexDirection:"column"}}>
                  <CircularProgress size={80} style={{ color: '#287B4C' }} >Please wait</CircularProgress> {/* Increased size */}
                  <br />
                  <br />
                  <Typography id="otp-modal-title" variant="h4" component="h2" color="#ffffff">
                      <span style={{color: '#ffffff'}}>Please Wait! Processing...</span>
                  </Typography>
              </div>
          )}
    {/* <ConnectComponentsProvider connectInstance={instance}>
      <ConnectAccountOnboarding
        onExit={() => {
          console.log("The account has exited onboarding");
        }}
        onStepChange={(stepChange) => {
          console.log(`User entered: ${stepChange.step}`);
        }}
      />
    </ConnectComponentsProvider> */}

      <form onSubmit={handleSubmitData} className="styled-form">
      <br />
      <Typography variant="h4" style={{fontFamily:'AvenirBlack'}}>New Seller <span style={{color:"#287B4C"}}>onboarding</span></Typography>
       <br />
       <br />
          <div className="form-group">
              <input
                  type="text"
                  placeholder="First Name"
                  {...register("firstName")}
                  name='firstName'
                  value={formData.firstName}
                  onChange={handleChange}
                  className="form-input"
              />
              {errors.firstName && <span className="error-message">{errors.firstName.message}</span>}
          </div>
          <div className="form-group">
              <input
                  type="text"
                  placeholder="Last Name"
                  {...register("lastName")}
                  name='lastName'
                  value={formData.lastName}
                  onChange={handleChange}
                  className="form-input"
              />
              {errors.lastName && <span className="error-message">{errors.lastName.message}</span>}
          </div>

          <div className="form-group">
              <input
                  type="text"
                  placeholder="Email"
                  {...register("email", {
                      pattern: {
                          value: /^\S+@\S+$/i,
                          message: "Invalid email format"
                      }
                  })}
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  className="form-input"
              />
              {errors.email && <span className="error-message">{errors.email.message}</span>}
          </div>

          <div className="form-group">
          <Controller
          name="phone"
          control={control}
          defaultValue=""
          rules={{ required: "Please enter your Mobile Number" }}
          render={({ field }) => (
            <PhoneInput
              {...field}
              country={'us'}
              enableSearch
              onChange={(value, country) => {
                  const formattedPhoneData = {
                      countryCode: country.countryCode.toUpperCase(),
                      phone: value.replace(`+${country.dialCode}`, '')
                    };
                  setFormData({ ...formData, mobileNumber: formattedPhoneData })
              }}
              inputStyle={{
                  width: '100%',
                  height: '61px',
                  padding: '10px 60px',
                  fontSize: '16px',
                  border: '1px solid #ddd',
                  borderRadius: '30px',
                  transition: 'all 0.3s ease',
                  boxSizing: 'border-box',
                }}
              placeholder="Enter mobile number"
              error={!!errors.phone}
            />
          )}
        />

          </div>

          <div className="form-group">
              <Controller
                  name="nationality"
                  control={control}
                  render={({ field }) => (
                      <Select
                          {...field}
                          onChange={(selectedOption) =>
                              setFormData({ ...formData, nationality: selectedOption.label })}
                          options={countryOptions}
                          placeholder="Nationality"
                          className="form-select"
                          styles={customStyles}
                          isClearable // Added to allow clearing of the select input
                      />
                  )}
              />
              {errors.nationality && <span className="error-message">{errors.nationality.message}</span>}
          </div>



          <div className="form-group">
              <input
                  type="text"
                  name='companyName'
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Name of the company"
                  {...register("companyName")}
                  className="form-input"
              />
          </div>

          <div className="form-group">
              <Controller
                  name="countryOfOperations"
                  control={control}
                  render={({ field }) => (
                      <Select
                          {...field}
                          options={countryOptions}
                          onChange={(selectedOption) =>
                              setFormData({ ...formData, countryOfOperations: selectedOption.label })}
                          placeholder="Country of operations"
                          className="form-select"
                          styles={customStyles}
                          isClearable
                      />
                  )}
              />
          </div>

          <div className="form-group">
              <input
                  type="text"
                  name='designation'
                  value={formData.designation}
                  onChange={handleChange}
                  placeholder="Designation"
                  {...register("designation")}
                  className="form-input"
              />
          </div>

          <div className="form-group">
              <input
                  type="text"
                  name='paymentPercentage'
                  value={formData.paymentPercentage}
                  onChange={handleChange}
                  placeholder="Payment Percentage"
                  {...register("paymentPercentage")}
                  className="form-input"
              />
          </div>



          <button type="submit" className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
          <br />
          <br />
      </form>
      </>
  );
}
export default requireAuthAdmin(OnboardSeller);