import React, { useEffect } from "react";
import { useParams } from "react-router-dom"; // If using React Router
// import { doc, updateDoc } from "firebase/firestore";
import {updateSellerAccount} from '../../util/db'
import { useHistory  } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { db } from "./firebaseConfig"; // Firebase Firestore instance

const OnboardingSuccess = () => {
  const {accountId} = useParams();
  const navigate = useHistory ();

  useEffect(() => {
    const updateAccountDetails = async () => {

      if (accountId) {
        try {
          // Call the database update function
          await updateSellerAccount(accountId, { status: "completed" });
          toast.success("Seller Onboarding successful")
          setTimeout(() => {
            navigate.push(`/admin/dashboard`);
          }, 1000);

        } catch (error) {
          toast.success("Seller Onboarding Failed")
          console.error("Failed to update account details:", error.message);
        }
      }
    };

    updateAccountDetails();
  }, [accountId]);

  return (
    <>
    <ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"

/>
    <div>
      <h2>Onboarding Successful!</h2>
      <h5>The seller account has been successfully onboarded with Stripe. A password reset email has been sent to the registered email address. Once the password is created, the seller can log in and begin onboarding customers to CropDefense stores in app.cropdefense.net.</h5>
    </div>
    </>
  );
};

export default OnboardingSuccess;
