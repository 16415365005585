import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
// import FeaturesSection from "./../components/FeaturesSection";
// import ClientsSection from "./../components/ClientsSection";
// import TestimonialsSection from "./../components/TestimonialsSection";
// import NewsletterSection from "./../components/NewsletterSection";
// import CtaSection from "./../components/CtaSection";

function IndexPage(props) {
  return (
    <>
      <Meta  title="CropDefense - Home"/>
      <HeroSection
        bgColor="#D6F3B3"
        size="large"
        bgImage=""
        bgImageOpacity={1}
        title={<span style={{fontWeight:"600"}}>CropDefense</span>}
        subtitle={<p style={{fontWeight:"600"}}>Our OMRI certified microbial seed + plant treatment increases crop yields up to 70% on both organic and conventional crops alike.
            <br/> <br/>
          CropDefense™ improves nutrient density and tolerance to environmental stressors, such as extreme temperatures, drought, and infertile soil.</p>}
        buttonText="Login"
        buttonColor="secondary"
        buttonPath="/auth/signin"
      />
      {/* <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle="All the features you need to move faster"
      />
      <ClientsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="You're in good company"
        subtitle=""
      />
      <TestimonialsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      />
      <NewsletterSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
      <CtaSection
        bgColor="primary"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Get Started"
        buttonColor="default"
        buttonPath="/pricing"
      /> */}
    </>
  );
}

export default IndexPage;
