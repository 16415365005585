import React, { useMemo, useEffect, useState } from 'react';
import { requireAuthSeller } from "../../util/auth";
import { getClients } from '../../util/db';
import Container from "@material-ui/core/Container";
import { MaterialReactTable,useMaterialReactTable } from 'material-react-table';
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../util/auth";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    padding: `0 ${theme.spacing(3)}px`,
    // textAlign: "center",
  },
}));


function MyClients() {
  
  const auth = useAuth();
  const classes = useStyles();
  const [clientData, setClientData] = useState([])

  const populateUser = async () => {
    const datadis = await getClients(auth?.user?.uid);
    setClientData(datadis);
  }

  useEffect(() => {
    populateUser();
  }, [])
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.createdAt.toDate().toLocaleDateString('en-US') ,
        header: 'Date',
        size: 50,
      },
      {
        accessorFn: (row) => row.firstName + ' ' + row.lastName ,
        header: 'Full Name',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'phone',
        header: 'Mobile Number',
      },
      {
        accessorKey: 'nationality',
        header: 'Nationality',
      },
      // {
      //   accessorKey: 'representingCompany',
      //   header: 'Representing Company',
      // },
      {
        accessorKey: 'company',
        header: 'Company Name',
      },
      {
        accessorKey: 'countryOfOperations',
        header: 'Country of Operations',
      },
      {
        accessorKey: 'designation',
        header: 'Designation',
      },
      {
        accessorKey: 'totalArea',
        header: 'Total Area',
      },
    ],
    []
  );
  return (
    <>
  <Container className={classes.container}>
    <br />
    <h2> My Clients</h2>
    <br />
        <MaterialReactTable
      columns={columns}
      data={clientData}
      enableColumnFilters
      enableSorting
      enablePagination
      initialState={{
        pagination: { pageSize: 5, pageIndex: 0 },
      }}
    />
    </Container>
    </>
  )
}

export default requireAuthSeller(MyClients);