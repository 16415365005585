import React, {useState} from 'react'
import { useForm, Controller } from 'react-hook-form';
import { requireAuthSeller } from "../../util/auth";
import Typography from "@material-ui/core/Typography";
import Select from 'react-select';
import countryList from 'react-select-country-list';
import '../forms.css';
import {onboardUserToWix} from '../../util/functions';
import {CircularProgress} from "@material-ui/core";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createClients } from '../../util/db';
import { useAuth } from "../../util/auth";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(() => ({
    formGroup: {
      marginBottom: '20px',
    },
    phoneInput: {
      width: '100%',
      height: '61px',
      padding: '10px 32px',
      fontSize: '16px',
      border: '1px solid #ddd',
      borderRadius: '30px',
      backgroundColor: '#fff',
      transition: 'all 0.3s ease',
      boxSizing: 'border-box',
      '&:focus': {
        outline: 'none',
        borderColor: '#007bff',
        boxShadow: '0 0 0 2px rgba(0, 123, 255, 0.25)',
      },
    },
  }));

function OnboardClient() {
    const auth = useAuth();
    const classes = useStyles();
    const [selectPhone, setSelectPhone] = useState('');
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const { register, control, formState: { errors, isSubmitting } } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            fullName: '',
            email: '',
            mobileNumber: '',
            nationality: null,
            representingCompany: null,
            companyName: '',
            countryOfOperations: null,
            designation: '',
            totalArea: ''
        }
    });

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: '',
        nationality: null,
        representingCompany: null,
        companyName: '',
        countryOfOperations: null,
        designation: '',
        totalArea: '',
        uid: auth?.user?.uid,
        referralEmail: auth?.user?.email,

      });

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
      };
    

      
      const handleSubmitData = async (e) => {
        e.preventDefault();
        setLoadingSubmit(true);
        if (formData.firstName === '' || formData.lastName === '' || formData.email === '' || formData.mobileNumber === '' || formData.nationality === null || formData.representingCompany === null || formData.countryOfOperations === null || formData.designation === '' || formData.totalArea === ''   ){
            toast.error(`Fill up the form completely to submit`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setLoadingSubmit(false);
              return

        }
        console.log(formData)
        await onboardUserToWix(formData);
        setLoadingSubmit(false);
        // await createClients(formData);
            toast.success(`Client details added successfully.`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });


    

    }
  
    const countryOptions = countryList().getData();



    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '30px',
            border: '1px solid #fff',
            boxShadow: 'none',
            '&:hover': {
                border: '1px solid #fff',
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '15px',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#287B4C' : state.isFocused ? '#f0f0f0' : null,
            color: state.isSelected ? 'white' : 'black',
        }),
    };

    return (
        <>
        <ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"

/>

{(loadingSubmit) && (
                <div className="loading-overlay" style={{display:"flex", flexDirection:"column"}}>
                    <CircularProgress size={80} style={{ color: '#287B4C' }} >Please wait</CircularProgress> {/* Increased size */}
                    <br />
                    <br />
                    <Typography id="otp-modal-title" variant="h4" component="h2" color="#ffffff">
                        <span style={{color: '#ffffff'}}>Please Wait! Processing...</span>
                    </Typography>
                </div>
            )}


        <form onSubmit={handleSubmitData} className="styled-form">
        <br />
        <Typography variant="h4" style={{fontFamily:'AvenirBlack'}}>New client <span style={{color:"#287B4C"}}>onboarding</span></Typography>
         <br />
         <br />
            <div className="form-group">
                <input
                    type="text"
                    placeholder="First Name"
                    {...register("firstName")}
                    name='firstName'
                    value={formData.firstName}
                    onChange={handleChange}
                    className="form-input"
                />
                {errors.firstName && <span className="error-message">{errors.firstName.message}</span>}
            </div>
            <div className="form-group">
                <input
                    type="text"
                    placeholder="Last Name"
                    {...register("lastName")}
                    name='lastName'
                    value={formData.lastName}
                    onChange={handleChange}
                    className="form-input"
                />
                {errors.lastName && <span className="error-message">{errors.lastName.message}</span>}
            </div>

            <div className="form-group">
                <input
                    type="text"
                    placeholder="Email"
                    {...register("email", {
                        pattern: {
                            value: /^\S+@\S+$/i,
                            message: "Invalid email format"
                        }
                    })}
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                    className="form-input"
                />
                {errors.email && <span className="error-message">{errors.email.message}</span>}
            </div>

            <div className="form-group">
            <Controller
            name="phone"
            control={control}
            defaultValue=""
            // rules={{ required: "Please enter your Mobile Number" }}
            render={({ field }) => (
              <PhoneInput
                {...field}
                country={'us'}
                enableSearch
                onChange={(value, country) => {
                    const formattedPhoneData = {
                        countryCode: country.countryCode.toUpperCase(),
                        phone: value.replace(`+${country.dialCode}`, '')
                      };
                    setFormData({ ...formData, mobileNumber: formattedPhoneData })
                }}
                inputStyle={{
                    width: '100%',
                    height: '61px',
                    padding: '10px 60px',
                    fontSize: '16px',
                    border: '1px solid #ddd',
                    borderRadius: '30px',
                    transition: 'all 0.3s ease',
                    boxSizing: 'border-box',
                  }}
                placeholder="Enter mobile number"
                error={!!errors.phone}
              />
            )}
          />
                {/* <input
                    type="tel"
                    name='mobileNumber'
                    value={formData.mobileNumber}
                    onChange={handleChange}
                    placeholder="Mobile number"
                    {...register("mobileNumber", {
                        minLength: { value: 6, message: "Mobile number must be at least 6 digits" },
                        maxLength: { value: 12, message: "Mobile number must not exceed 12 digits" }
                    })}
                    className="form-input"
                />
                {errors.mobileNumber && <span className="error-message">{errors.mobileNumber.message}</span>} */}
            </div>

            <div className="form-group">
                <Controller
                    name="nationality"
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            onChange={(selectedOption) =>
                                setFormData({ ...formData, nationality: selectedOption.label })}
                            options={countryOptions}
                            placeholder="Nationality"
                            className="form-select"
                            styles={customStyles}
                            isClearable // Added to allow clearing of the select input
                        />
                    )}
                />
                {errors.nationality && <span className="error-message">{errors.nationality.message}</span>}
            </div>

            <div className="form-group">
                <Controller
                    name="representingCompany"
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            options={[
                                { value: "yes", label: "Yes" },
                                { value: "no", label: "No" }
                            ]}
                            onChange={(selectedOption) =>
                                setFormData({ ...formData, representingCompany: selectedOption.label })}
                            placeholder="Are you representing a company?"
                            className="form-select"
                            styles={customStyles}
                            isClearable
                        />
                    )}
                />
                {errors.representingCompany && <span className="error-message">{errors.representingCompany.message}</span>}
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name='companyName'
                    value={formData.companyName}
                    onChange={handleChange}
                    placeholder="Name of the company"
                    {...register("companyName")}
                    className="form-input"
                />
            </div>

            <div className="form-group">
                <Controller
                    name="countryOfOperations"
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            options={countryOptions}
                            onChange={(selectedOption) =>
                                setFormData({ ...formData, countryOfOperations: selectedOption.label })}
                            placeholder="Country of operations"
                            className="form-select"
                            styles={customStyles}
                            isClearable
                        />
                    )}
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name='designation'
                    value={formData.designation}
                    onChange={handleChange}
                    placeholder="Your Designation"
                    {...register("designation")}
                    className="form-input"
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name='totalArea'
                    value={formData.totalArea}
                    onChange={handleChange}
                    placeholder="Total Area"
                    {...register("totalArea")}
                    className="form-input"
                />
            </div>

            <button type="submit" className="submit-button" disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
            <br />
            <br />
        </form>
        </>
    );
}

export default requireAuthSeller(OnboardClient);
