// OrderDetailsAdmin.js
import React, { useEffect, useMemo, useState } from 'react';
import Container from "@material-ui/core/Container";
import { MaterialReactTable } from 'material-react-table';
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from 'react-toastify';
import Typography from "@material-ui/core/Typography";
import {CircularProgress} from "@material-ui/core";
import 'react-toastify/dist/ReactToastify.css';
import { requireAuthAdmin } from "../../util/auth";
import {getOrderDetails} from "../../util/db";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    padding: `0 ${theme.spacing(3)}px`,
  },
  printButton: {
    backgroundColor: '#4caf50',
    color: 'white',
    '&:hover': {
      backgroundColor: '#45a049',
    },
  }
}));
function OrderDetailsAdmin() {
  const classes = useStyles();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [data, setData] = useState([]);

  const getOrderData = async () => {
    const odata = await getOrderDetails();
    console.log(odata);
    setData(odata);
  }
  
  useEffect(() => {
    getOrderData();

  }, [])
  



  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.createdAt.toDate().toLocaleDateString('en-US') ,
        header: 'Date',
        size: 50,
      },
      {
        accessorFn: (row) => row.id,
        header: 'Order Id',
      },

      {
        accessorFn: (row) => row.buyerEmail,
        header: 'Customer Email',
      },
      {
        accessorKey: 'sellerEmail',
        header: 'Seller Email',
      },
      {
        accessorKey: 'commissionAmount',
        header: 'Commission Amount',
      },
      {
        accessorKey: 'commissionStatus',
        header: 'Commission Status',
      },
      {
        accessorKey: 'manufacturerAmount',
        header: 'Manufacturer Amount',
      },
      {
        accessorKey: 'manufacturerPaymentStatus',
        header: 'Manufacturer Payment Status',
      },
      {
        accessorFn: (row) => row.priceSummary.subtotal.value,
        header: 'Sub Total',
      },
      {
        accessorFn: (row) => row.priceSummary.total.value,
        header: 'Total',
      },
      {
        accessorFn: (row) => row.priceSummary.total.currency,
        header: 'Currency',
      },
      

    ],
    []
  );

  return (
    <>
    <ToastContainer
position="bottom-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"

/>


{(loadingSubmit) && (
                <div className="loading-overlay" style={{display:"flex", flexDirection:"column"}}>
                    <CircularProgress size={80} style={{ color: '#287B4C' }} >Please wait</CircularProgress> {/* Increased size */}
                    <br />
                    <br />
                    <Typography id="otp-modal-title" variant="h4" component="h2" color="#ffffff">
                        <span style={{color: '#ffffff'}}>Please Wait! Processing...</span>
                    </Typography>
                </div>
            )}

    <Container className={classes.container}>
            <br />
      <h2>Order Details - Admin</h2>
      <br />
    <MaterialReactTable
      columns={columns}
      data={data}
      enableColumnFilters
      enableSorting
      enablePagination
      initialState={{
        pagination: { pageSize: 5, pageIndex: 0 },
      }}
    />
    </Container>
    </>
  );
}

export default requireAuthAdmin(OrderDetailsAdmin);