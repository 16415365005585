import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import DashboardItems from "./DashboardItems";
import Button from "@material-ui/core/Button";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import { getClientsCount } from "../util/db";
import PaidIcon from '@mui/icons-material/Paid';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { ContactSupportOutlined } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
}));

function DashboardSectionMaker(props) {
  const auth = useAuth();
  const classes = useStyles();

  const [clientData, setClientData] = React.useState(0)

  const populateUser = async () => {
    const datadis = await getClientsCount(auth?.user?.uid);
    setClientData(datadis);
  }

  React.useEffect(() => {
    populateUser();
  }, [])
  const router = useRouter();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
      <br />
      <h2>Welcome,  {auth?.user?.profileName}!</h2>
      <br />
        {/* <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        /> */}

        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}

        <Grid container={true} spacing={4}>
        <Grid item={true} xs={12} md={4}>
        <a href="/supply/orders" style={{textDecoration: "none"}}>
                  <Box sx={{backgroundColor:'white', borderRadius:"20px", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.15)", height:{xs:"150px",md:"200px", xl:"250px"}, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box  sx={{color:'Black', fontWeight:'400', padding: '20px' , width:"100%",padding: '20px' ,fontSize:{xs:"13px",md:"14px", xl:"15px"}}} >
                  <div  style={{display:"flex",  justifyContent: 'space-between'}} >
                  <PaidIcon></PaidIcon> 
                  {/* <img src={'/clients.png'}  style={{height: '20px'}} ></img> */}
                  <img src={'/arrow_black.png'}  style={{height: '20px'}} ></img>
                  </div>
                  <br />
                  <br />
                
<br />
<Box  sx={{color:'Black', fontWeight:'700',fontSize:{xs:"25px",md:"30px", xl:"30px"}}}  >
My Orders
  </Box>
  </Box>
</Box>
</a>
</Grid>
        <Grid item={true} xs={12} md={4} >
                 <a href="/supply/payments" style={{textDecoration: "none"}}>
                  <Box sx={{ backgroundColor:'#277A4C', borderRadius:"20px", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.15)", height:{xs:"150px",md:"200px", xl:"250px"}, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                 
                  <Box  sx={{color:'white', padding: '20px' , width:"100%",fontWeight:'400', fontSize:{xs:"13px",md:"14px", xl:"15px"}}}  >
                  <div  style={{display:"flex",  justifyContent: 'space-between'}} >
                  <AccountBalanceIcon />
                  {/* <img src={'/add_client.png'}  style={{height: '20px'}} ></img> */}
                  {/* <PaidIcon></PaidIcon> */}
                  <img src={'/arrow_white.png'}  style={{height: '20px'}} ></img>
                  </div>
                  <br />
                  <br />
                 My


<br />
<Box  sx={{color:'white', fontWeight:'700',fontSize:{xs:"25px",md:"30px", xl:"30px"}}}  >
Payments
  </Box>



                  </Box>
                  {/* <Box sx={{ padding: '20px', display:"flex", alignItems: 'center', justifyContent: 'space-between'}}> <img src={'/arrow_white.png'}  style={{height: '20px'}} ></img></Box> */}

</Box>
</a>
</Grid>



        </Grid>
      </Container>
    </Section>
  );
}

export default DashboardSectionMaker;
