import React from "react";
import Container from "@material-ui/core/Container";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Auth from "./Auth";
import AuthFooter from "./AuthFooter";

function AuthSection(props) {

    // Add role-specific configurations
    const configByRole = {
      admin: {
        welcomeText: "Welcome to CropDefense Admin Portal",
        titlePrefix: "Admin"
      },
      seller: {
        welcomeText: "Welcome to CropDefense Seller Portal",
        titlePrefix: "Seller"
      },
      supply: {
        welcomeText: "Welcome to CropDefense Supply Portal",
        titlePrefix: "Supply"
      }
    };

    const roleConfig = configByRole[props.role] || {};

  // Options by auth type
  const optionsByType = {
    signup: {
      // Top Title
      title: "Get yourself an account",
      // Button text
      buttonAction: "Sign up",
      // Footer text and links
      showFooter: true,
      signinText: "Already have an account?",
      signinAction: "Sign in",
      signinPath: "/auth/signin",
      // Terms and privacy policy agreement
      showAgreement: true,
      termsPath: "/legal/terms-of-service",
      privacyPolicyPath: "/legal/privacy-policy",
    },
    signin: {
      title: `Login to your ${roleConfig.titlePrefix} account`,
      buttonAction: "Sign in",
      showFooter: true,
      signupAction: "Create an account",
      signupPath: "/auth/signup",
      forgotPassAction: "Forgot Password?",
      forgotPassPath: "/auth/forgotpass",
    },
    forgotpass: {
      title: "Get a new password",
      buttonAction: "Reset password",
      showFooter: true,
      signinText: "Remember it after all?",
      signinAction: "Sign in",
      signinPath: "/auth/signin",
    },
    changepass: {
      title: "Choose a new password",
      buttonAction: "Change password",
    },
  };

  // Ensure we have a valid auth type
  const type = optionsByType[props.type] ? props.type : "signup";

  // Get options object for current auth type
  const options = optionsByType[type];
  // const roleConfig = configByRole[props.role] || {};

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="xs">
        <p style={{textAlign: 'center', fontSize:"32px", fontFamily: 'AvenirBlack', color: '#277A4C'}}> {roleConfig.welcomeText || "Welcome to CropDefense"}</p>
        <SectionHeader
          title={options.title}
          subtitle=""
          size={5}
          textAlign="center"
        />
        <Auth
          type={type}
          buttonAction={options.buttonAction}
          providers={props.providers}
          afterAuthPath={props.afterAuthPath}
          key={type}
        />

        {options.showFooter && <AuthFooter type={type} {...options} />}
      </Container>
    </Section>
  );
}

export default AuthSection;
