import React from "react";
import Meta from "./../components/Meta";
import { requireAuthFirst, useAuth } from "./../util/auth";
import CircularProgress from "@material-ui/core/CircularProgress";

function AfterAuth(props) {
  return (
    <>
      <Meta title="Verify Email" />
      <div
        style={{
          padding: "50px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <CircularProgress size={28} />
      </div>
    </>
  );
}

export default requireAuthFirst(AfterAuth);
