import React, { useMemo, useState, useEffect } from 'react';
import Container from "@material-ui/core/Container";
import { MaterialReactTable } from 'material-react-table';
import { makeStyles } from "@material-ui/core/styles";
import { getUsersAdmin } from '../../util/db';
import { requireAuthAdmin, useAuth } from "../../util/auth";


const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    padding: `0 ${theme.spacing(3)}px`,
  },
  totalAmount: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
    fontWeight: 'bold',
  },
  statusChip: {
    padding: theme.spacing(0.5, 1.5),
    borderRadius: '16px',
    fontWeight: 'bold',
    display: 'inline-block',
    fontSize: '0.875rem',
  },
  completed: {
    backgroundColor: '#e8f5e9',
    color: '#2e7d32',
  }
}));


function ManageUsers() {
  const auth = useAuth();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const populateUser = async () => {
    const datadis = await getUsersAdmin();
    setData(datadis);
  }

  useEffect(() => {
    populateUser();
  }, [])



  const columns = useMemo(
    () => [
      {
        accessorKey: 'email',
        header: 'Email',
        size: 250,
      },
      {
        accessorFn: (row) => row.firstName + ' ' + row.lastName,
        header: 'Name',
      },
      {
        accessorKey: 'role',
        header: 'Role',
      },
      {
        accessorKey: 'paymentPercentage',
        header: 'Payment Percentage',
        size: 250,
      },
      {
        accessorKey: 'nationality',
        header: 'Country',
      },

      {
        accessorKey: 'accountId',
        header: 'Stripe Id',
        size: 250,
      }

    ],
    [classes]
  );

  return (
    <Container className={classes.container}>
      <br />
      <h2>Manage Users</h2>
      <br />
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnFilters
        enableSorting
        enablePagination
        initialState={{
          pagination: { pageSize: 5, pageIndex: 0 },
          sorting: [{ id: 'paymentDate', desc: true }],
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '8px',
            border: '1px solid #e0e0e0',
          },
        }}
        muiTableProps={{
          sx: {
            tableLayout: 'fixed',
          },
        }}
      />

    </Container>
  );
}
export default requireAuthAdmin(ManageUsers);