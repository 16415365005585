// MyPaymentsManufacturer.js
import React, { useMemo, useState } from 'react';
import Container from "@material-ui/core/Container";
import { MaterialReactTable } from 'material-react-table';
import { makeStyles } from "@material-ui/core/styles";
import { requireAuthManufacturer, useAuth } from "../../util/auth";
import {getTransactionsData} from "../../util/db";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    padding: `0 ${theme.spacing(3)}px`,
  },
  totalAmount: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
    fontWeight: 'bold',
  },
  statusChip: {
    padding: theme.spacing(0.5, 1.5),
    borderRadius: '16px',
    fontWeight: 'bold',
    display: 'inline-block',
    fontSize: '0.875rem',
  },
  completed: {
    backgroundColor: '#e8f5e9',
    color: '#2e7d32',
  }
}));

function MyPaymentsManufacturer() {
  const auth = useAuth();
  const classes = useStyles();
  
  // Mock data for manufacturer payments
  const [data, setData] = useState([]);
  const getTransactionData = async () => {
    const odata = await getTransactionsData();
    console.log(odata);
    setData(odata);
  }
  
  React.useEffect(() => {
    getTransactionData(auth?.user?.email);

  }, [])
  // Calculate total received payments
  const totalReceivedPayments = data.reduce((sum, item) => sum + item.amount, 0);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'type',
        header: 'Type',
      },
      {
        accessorKey: 'orderId',
        header: 'Order id',
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
      },
      {
        accessorKey: 'currency',
        header: 'Currency',
      },
      {
        accessorKey: 'toEmail',
        header: 'To Email',
      },
      {
        accessorFn: (row) => row.createdAt.toDate().toLocaleDateString('en-US') ,
        header: 'Date',
        size: 50,
      },

      {
        accessorFn: (row) => row.transferDetails.id ,
        header: 'Transaction Id',
        size: 50,
      },

    ],
    []
  );

  return (
    <Container className={classes.container}>
      <br />
      <h2>My Payments - Manufacturer</h2>
      <br />
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnFilters
        enableSorting
        enablePagination
        initialState={{
          pagination: { pageSize: 5, pageIndex: 0 },
          sorting: [{ id: 'paymentDate', desc: true }],
        }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: '8px',
            border: '1px solid #e0e0e0',
          },
        }}
        muiTableProps={{
          sx: {
            tableLayout: 'fixed',
          },
        }}
      />
      <div className={classes.totalAmount}>
        Total Received Payments: ${totalReceivedPayments.toFixed(2)}
      </div>
    </Container>
  );
}

export default requireAuthManufacturer(MyPaymentsManufacturer);