import React from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router";

function AuthPage(props) {
  const router = useRouter();
  const role = router.query.role; // This will be 'admin', 'seller', or 'maker'

  return (
    <>
      <Meta title={`${role.charAt(0).toUpperCase() + role.slice(1)} Auth`} />
      <AuthSection
        bgColor="#EFEFEF"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        type={router.query.type}
        role={role} // Pass the role to AuthSection
        providers={["google", "facebook", "twitter"]}
        afterAuthPath={router.query.next || `/${role}/dashboard`} // Customize redirect path based on role
      />
    </>
  );
}

export default AuthPage;
