import React from "react";
import Meta from "../../components/Meta";
import DashboardSection from "../../components/DashboardSection";
import { requireAuthSeller } from "../../util/auth";

function DashboardPage(props) {
  return (
    <>
      <Meta title="Dashboard" />
      <DashboardSection
        bgColor="#EFEFEF"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Dashboard"
        subtitle=""
      />
    </>
  );
}

export default requireAuthSeller(DashboardPage);
