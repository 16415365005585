// ProjectedPaymentsSeller.js
import React, { useMemo, useState } from 'react';
import Container from "@material-ui/core/Container";
import { MaterialReactTable } from 'material-react-table';
import { makeStyles } from "@material-ui/core/styles";
import { requireAuthSeller,useAuth } from "../../util/auth";
import {getOrderDetailsProjected} from "../../util/db";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    padding: `0 ${theme.spacing(3)}px`,
  },
  totalAmount: {
    marginTop: theme.spacing(2),
    textAlign: 'right',
    fontWeight: 'bold',
  }
}));

function ProjectedPaymentsSeller() {
  const auth = useAuth();
  const classes = useStyles();
  
  // Mock data for projected payments
  const [data, setData] = useState([]);
  const getOrderData = async () => {
    const odata = await getOrderDetailsProjected(auth?.user?.email);
    console.log(odata);
    setData(odata);
  }
  
  React.useEffect(() => {
    getOrderData();

  }, [])

  // Calculate total projected commission
  const totalProjectedCommission = data
    .filter(item => item.paymentStatus === 'PAID')
    .reduce((sum, item) => sum + item.commissionAmount, 0);

    const columns = useMemo(
      () => [
        {
          accessorFn: (row) => row.createdAt.toDate().toLocaleDateString('en-US') ,
          header: 'Date',
          size: 50,
        },
        {
          accessorFn: (row) => row.id,
          header: 'Order Id',
        },
  
        {
          accessorFn: (row) => row.buyerEmail,
          header: 'Customer Email',
        },
        {
          accessorKey: 'sellerEmail',
          header: 'Seller Email',
        },
        {
          accessorKey: 'commissionAmount',
          header: 'Commission Amount',
        },
        {
          accessorKey: 'commissionStatus',
          header: 'Commission Status',
        },
        {
          accessorFn: (row) => row.priceSummary.subtotal.value,
          header: 'Total',
        },
        {
          accessorFn: (row) => row.priceSummary.total.currency,
          header: 'Currency',
        },
  
      ],
      []
    );

  return (
    <Container className={classes.container}>
      <br />
      <h2>Projected Payments - Seller</h2>
      <br />
      <div className={classes.totalAmount}>
        Total Projected Commission: ${totalProjectedCommission.toFixed(2)}
      </div>
      <br />
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnFilters
        enableSorting
        enablePagination
        initialState={{
          pagination: { pageSize: 5, pageIndex: 0 },
          sorting: [{ id: 'orderDate', desc: true }],
        }}
      />

    </Container>
  );
}

export default requireAuthSeller(ProjectedPaymentsSeller);