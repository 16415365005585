import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    height: "100%",
    padding: `0 ${theme.spacing(3)}px`,
    // textAlign: "center",
  },


}));


function HeroSection(props) {
  const classes = useStyles();
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
      <Grid
          container={true}
          alignItems="center"
          justifyContent="center"
          spacing={5}
        >
          <Grid item={true} xs={12} md={6} >
          <img src="/image.png" style={{maxHeight:"500px"}}></img>
          </Grid>

          <Grid item={true} xs={12} md={6} >
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={4}
          />
          <div style={{display:'flex', gap:'20px'}}>
          <Button
          style={{
            borderRadius: '20px', // Adjust the border radius here
            padding: '10px 30px',  // Adjust the padding here (top-bottom, left-right)
          }} 
            component={Link}
            to={'/admin/auth/signin'}
            variant="contained"
            size="large"
            color={props.buttonColor}
          >
            Admin Login
          </Button>
          <Button
          style={{
            borderRadius: '20px', // Adjust the border radius here
            padding: '10px 30px',  // Adjust the padding here (top-bottom, left-right)
          }} 
            component={Link}
            to={'/seller/auth/signin'}
            variant="contained"
            size="large"
            color={props.buttonColor}
          >
            Seller Login
          </Button>
          <Button
          style={{
            borderRadius: '20px', // Adjust the border radius here
            padding: '10px 30px',  // Adjust the padding here (top-bottom, left-right)
          }} 
            component={Link}
            to={'/supply/auth/signin'}
            variant="contained"
            size="large"
            color={props.buttonColor}
          >
            Supply Login
          </Button>
          </div>
          </Grid>

          
        </Grid>
      </Container>
    </Section>
  );
}

export default HeroSection;
